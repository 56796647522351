<template>
	<v-dialog
		v-model="dialog"
		content-class="locations-dialog"
		max-width="1320"
	>
		<v-row no-gutters style="height: 100%">
			<!-- Map -->
			<v-col cols="12" md="8" class="mapbox-container">
				<MglMap
					ref="mapbox"
					class="mapbox"
					:accessToken="accessToken"
					:mapStyle.sync="mapStyle"
					@load="onLoad"
				>
					<div
						v-for="(location, index) in locationMarkers"
						:key="index"
					>
						<MglMarker
							v-if="
								location.location.longitude &&
								location.location.latitude
							"
							:coordinates="[
								location.location.longitude,
								location.location.latitude,
							]"
							@click="openLocation(location)"
						>
							<div slot="marker">
								<v-icon size="40px" :color="location.color"
									>mdi-map-marker</v-icon
								>
							</div>
						</MglMarker>
					</div>
					<MglMarker
						v-if="
							currentCoordinates &&
							currentCoordinates[0] != 0 &&
							currentCoordinates[1] != 0
						"
						:coordinates="[
							currentCoordinates[1],
							currentCoordinates[0],
						]"
					>
						<div slot="marker">
							<v-icon size="40px" color="#E60000"
								>mdi-map-marker-account</v-icon
							>
						</div>
					</MglMarker>
				</MglMap>
			</v-col>

			<!-- Cards -->
			<v-col cols="12" md="4" class="location-list">
				<location-list-card
					v-for="card in locationCards"
					:key="card.id"
					:card="card"
					:ref="'card-' + card.id"
					@click="flyTo"
				/>
			</v-col>
		</v-row>

		<div class="location-close">
			<v-icon class="mt-1 mr-2" @click="dialog = false">mdi-close</v-icon>
		</div>

		<location-dialog ref="locationDialog" />
	</v-dialog>
</template>

<script>
import { EventBus } from "@/event-bus.js";

import Mapbox from "mapbox-gl";
import { MglMap, MglMarker } from "vue-mapbox";

import LocationDialog from "@/components/locations/LocationDialog.vue";
import LocationListCard from "@/components/locations/LocationListCard.vue";

const ACCESS_TOKEN = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN;
const MAP_STYLE = "mapbox://styles/mapbox/light-v9";

export default {
	name: "LocationsDialog",
	components: {
		LocationListCard,
		LocationDialog,
		MglMap,
		MglMarker,
	},
	data() {
		return {
			dialog: false,
			accessToken: ACCESS_TOKEN,
			mapStyle: MAP_STYLE,

			bounds: null,

			// Used when flyTo but map not loaded yet
			mapLoaded: false,
			bufferLocation: null,
		};
	},
	created() {
		this.mapbox = Mapbox;
	},
	mounted() {
		EventBus.$on("open-locations-dialog", (id, location) => {
			this.dialog = true;

			this.$nextTick(() => {
				this.openLocations(id);
			});

			if (this.mapLoaded) {
				this.flyTo(location);
			} else {
				this.bufferLocation = location;
			}
		});
	},
	computed: {
		cards() {
			return this.$store.getters["cards/getCards"];
		},
		currentCoordinates() {
			return this.$store.getters["user/getCoordinates"];
		},
		locationCards() {
			if (this.cards == null) return [];
			let locationCards = [];

			for (let i = 0; i < this.cards.length; ++i) {
				let locations = this.cards[i].campaign.locations;

				if (locations.length) locationCards.push(this.cards[i]);
			}
			return locationCards;
		},
		locationMarkers() {
			if (this.locationCards == null) return [];
			let tmpIndexMap = [];
			let locationMarkers = [];

			for (let i = 0; i < this.locationCards.length; ++i) {
				let card = this.locationCards[i];
				let locations = card.campaign.locations;

				for (let j = 0; j < locations.length; ++j) {
					// Fallback for shit location system
					let identifier =
						locations[j].latitude + locations[j].longitude;

					// Check if location already exists
					if (tmpIndexMap[identifier] === undefined) {
						// Store index in map
						tmpIndexMap[identifier] =
							locationMarkers.push({
								location: locations[j],
								color: this.calculateCategoryColor(
									card.categoryId
								),
								cards: [],
							}) - 1;
					}

					let index = tmpIndexMap[identifier];

					// Push campaign to location
					locationMarkers[index].cards.push(card);
				}
			}

			return locationMarkers;
		},
	},
	methods: {
		calculateCategoryColor(categoryId) {
			let category =
				this.$store.getters["directory/category"](categoryId);
			if (category && category.locationMarkerColor)
				return category.locationMarkerColor;
			return "#" + Math.floor(Math.random() * 16777215).toString(16);
		},
		openLocations(id) {
			for (let i = 0; i < this.locationCards.length; ++i) {
				let card = this.locationCards[i];

				this.$refs["card-" + card.id][0].toggle(card.id == id);
			}
		},
		flyTo(location) {
			if (location) {
				this.$refs.mapbox.map.flyTo({
					center: [location.longitude, location.latitude],
					zoom: 16,
					speed: 3,
					essential: true,
				});
			} else {
				this.$refs.mapbox.map.fitBounds(this.bounds, {
					padding: this.$vuetify.breakpoint.mdAndUp ? 150 : 50,
					duration: 0,
				});
			}
		},
		onLoad() {
			this.mapLoaded = true;

			this.flyTo(this.bufferLocation);

			this.bufferLocation = null;
		},
		openLocation(location) {
			this.$refs.locationDialog.open(location);
		},
	},
};
</script>

<style>
.locations-dialog {
	position: relative;
	width: 100%;
	height: 100%;
	max-height: 600px !important;
}

.mapboxgl-marker {
	cursor: pointer;
}
</style>

<style scoped>
.mapbox {
	background-color: white;
}

.mapbox-container {
	display: flex;
	align-items: center !important;
	max-height: 600px;
}

.location-list {
	overflow-y: auto;
	background-color: white;
	max-height: 600px;
}

@media (max-width: 960px) {
	.mapbox-container,
	.location-list {
		height: 50%;
	}
}

.location-close {
	text-align: right !important;
	position: absolute;
	top: 0px;
}
</style>