<template>
    <v-dialog v-model="dialog" width="500">
        <v-card v-if="data">
            <v-card-title>
                <v-btn
                    class="dialog__close"
                    icon
                    x-small
                    @click.native="dialog = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>

                <div v-if="location.name != ''" v-text="location.name" />
            </v-card-title>

            <v-card-text>
                <!-- Location data -->
                <div class="d-flex justify-space-around">
                    <div id="location-address">
                        <div v-if="location.address != ''">
                            <label v-text="location.address" />
                        </div>

                        <div v-if="location.zip != '' || location.city != ''">
                            <label v-text="location.zip" class="pr-1" />
                            <label v-text="location.city" />
                        </div>

                        <div v-if="location.country != ''">
                            <label v-text="location.country" />
                        </div>
                    </div>

                    <div
                        id="location-contact"
                        class="d-flex align-center justify-center"
                    >
                        <v-btn
                            v-if="location.phone"
                            icon
                            @click="href('tel:' + location.phone)"
                        >
                            <v-icon>mdi-phone-in-talk</v-icon>
                        </v-btn>

                        <v-btn
                            v-if="location.email"
                            icon
                            @click="href('mailto:' + location.email)"
                        >
                            <v-icon>mdi-email</v-icon>
                        </v-btn>

                        <v-btn
                            v-if="location.website"
                            icon
                            target="_blank"
                            @click="href(location.website)"
                        >
                            <v-icon>mdi-web</v-icon>
                        </v-btn>

                        <v-btn
                            v-if="location.address != ''"
                            icon
                            target="_blank"
                            @click="
                                href(
                                    'https://maps.google.com/?daddr=' +
                                        location.address +
                                        '%20' +
                                        location.zip +
                                        '%20' +
                                        location.city
                                )
                            "
                        >
                            <v-icon>mdi-directions</v-icon>
                        </v-btn>

                        <strong v-if="location.distance" class="ml-2">
                            {{
                                parseDistance(
                                    location.distance,
                                    directory.distanceUnit
                                )
                            }}
                        </strong>
                    </div>
                </div>

                <!-- Active campaigns for location -->
                <location-card
                    v-for="card in data.cards"
                    :key="card.id"
                    :card="card"
                />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import locationHelpers from "@/mixins/locationHelpers.js";

import LocationCard from "@/components/locations/LocationCard.vue";

export default {
    name: "LocationDialog",
    components: {
        LocationCard,
    },
    data() {
        return {
            dialog: false,
            data: null,
        };
    },
    mixins: [locationHelpers],
    computed: {
        directory() {
            return this.$store.getters["directory/getDirectory"];
        },
        location() {
            return this.data.location;
        },
    },
    methods: {
        open(data) {
            this.data = data;
            this.dialog = true;
        },
        href(location) {
            window.open(location, "_blank");
        },
    },
};
</script>

<style>
</style>