<template>
    <v-card
        class="mt-4"
        :style="pointerStyle"
        @click.native="cardClickable ? redeemClicked() : null"
    >
        <div class="d-flex">
            <v-avatar size="112" tile>
                <v-img :src="card.campaign.image" />
            </v-avatar>

            <div class="card-content pa-4 pt-2 pb-2">
                <h2
                    v-if="cardSettings.title"
                    class="overflow-ellipsis"
                    v-html="card.campaign[cardSettings.title]"
                    :title="card.campaign[cardSettings.title]"
                />

                <div
                    v-if="cardSettings.subtitle"
                    class="overflow-ellipsis text-subtitle-1"
                    v-html="card.campaign[cardSettings.subtitle]"
                    :title="card.campaign[cardSettings.subtitle]"
                />

                <!-- View/redeem button -->
                <div v-if="cardSettings.showRedeem">
                    <button
                        class="redeem"
                        :style="buttonStyleIgnoreWidth"
                        :loading="loading"
                        :disabled="loadingState"
                        @click.prevent.stop="redeemClicked"
                    >
                        <v-progress-circular
                            v-if="loading"
                            indeterminate
                            :size="20"
                            :width="2"
                        ></v-progress-circular>

                        <label v-else>{{ cardSettings.buttonText }}</label>
                    </button>
                </div>
            </div>
        </div>
    </v-card>
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { redeem } from "@/services/redeem.js";

import layoutHelpers from "@/mixins/layoutHelpers.js";

export default {
    name: "LocationCard",
    props: {
        card: Object,
    },
    data() {
        return {
            loading: false,
        };
    },
    mixins: [layoutHelpers],
    mounted() {
        EventBus.$on("reset-loading", () => {
            this.loading = false;
        });
    },
    computed: {
        cardSettings() {
            return this.$store.getters["directory/getDirectory"].settingsCard;
        },
        pointerStyle() {
            return {
                cursor: this.cardSettings.cardClickable ? "pointer" : "default",
            };
        },
        cardClickable() {
            return this.cardSettings.cardClickable && !this.loadingState;
        },
        loadingState() {
            return this.$store.getters["cards/loading"];
        },
    },
    methods: {
        redeemClicked() {
            this.loading = true;

            redeem(this.card);
        },
    },
};
</script>

<style scoped>
.redeem {
    min-width: 100px;
}

.card-content {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-around !important;
    overflow: hidden;
}
</style>