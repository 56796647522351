<template>
	<div id="location-list-card" class="cursor-pointer">
		<div class="d-flex" @click="open = !open" style="height: 100px">
			<v-avatar class="card-image" size="100" tile>
				<v-img :src="image" />
			</v-avatar>

			<div class="card-content pa-4 pt-2 pb-2">
				<h3
					v-if="cardSettings.title"
					class="overflow-ellipsis"
					v-html="card.campaign[cardSettings.title]"
					:title="card.campaign[cardSettings.title]"
				/>

				<div
					v-if="cardSettings.subtitle"
					class="overflow-ellipsis text-subtitle-1 mb-1"
					v-html="card.campaign[cardSettings.subtitle]"
					:title="card.campaign[cardSettings.subtitle]"
				/>

				<h5 class="overflow-ellipsis">
					{{ locations.length }} Location(s)
				</h5>
			</div>

			<div class="card-end">
				<v-icon x-large>
					{{ open ? "mdi-chevron-up" : "mdi-chevron-down" }}
				</v-icon>

				<strong v-if="card.closest">
					{{
						parseDistance(
							card.closest.distance,
							directory.distanceUnit
						)
					}}
				</strong>
			</div>
		</div>

		<v-container class="locations" v-if="open">
			<v-row>
				<v-col
					cols="12"
					v-for="location in locations"
					:key="location.id"
					class="d-flex location-item cursor-pointer"
					@click="$emit('click', location)"
				>
					<div class="location-address subtitle-2">
						<div v-if="location.name != ''">
							<label v-text="location.name" />
						</div>

						<div v-if="location.address != ''">
							<label v-text="location.address" />
						</div>

						<div v-if="location.zip || location.city">
							<label
								v-if="location.zip"
								v-text="location.zip"
								class="pr-1"
							/>
							<label
								v-if="location.city"
								v-text="location.city"
							/>
						</div>

						<div v-if="location.country != ''">
							<label v-text="location.country" />
						</div>
					</div>

					<div
						class="location-contact d-flex align-center align-content-center justify-center"
					>
						<v-btn
							v-if="location.phone"
							icon
							@click.prevent.stop="href('tel:' + location.phone)"
						>
							<v-icon>mdi-phone-in-talk</v-icon>
						</v-btn>

						<v-btn
							v-if="location.email"
							icon
							@click.prevent.stop="
								href('mailto:' + location.email)
							"
						>
							<v-icon>mdi-email</v-icon>
						</v-btn>

						<v-btn
							v-if="location.website"
							icon
							target="_blank"
							@click.prevent.stop="href(location.website)"
						>
							<v-icon>mdi-web</v-icon>
						</v-btn>

						<v-btn
							v-if="location.address != ''"
							icon
							target="_blank"
							@click.prevent.stop="
								href(
									`https://maps.google.com/?daddr=${location.address}%20${location.zip}%20${location.city}`
								)
							"
						>
							<v-icon>mdi-directions</v-icon>
						</v-btn>
					</div>

					<div
						v-if="location.distance"
						class="location-distance d-flex"
					>
						<strong>
							{{
								parseDistance(
									location.distance,
									directory.distanceUnit
								)
							}}
						</strong>
					</div>
				</v-col>
			</v-row>
		</v-container>

		<v-divider />
	</div>
</template>

<script>
import locationHelpers from "@/mixins/locationHelpers.js";

export default {
	name: "LocationListCard",
	props: {
		card: Object,
	},
	mixins: [locationHelpers],
	data() {
		return {
			open: false,
			locations: [],
		};
	},
	mounted() {
		let tmpLocations = this.card.campaign.locations;

		if (this.locationSort) {
			// Sort locations based on distance
			tmpLocations.sort((a, b) => (a.distance > b.distance ? 1 : -1));
		}

		this.locations = tmpLocations;
	},
	computed: {
		directory() {
			return this.$store.getters["directory/getDirectory"];
		},
		cardSettings() {
			return this.$store.getters["directory/getDirectory"].settingsCard;
		},
		image() {
			return this.card.customImage ?? this.card.campaign.image;
		},
		locationSort() {
			let directory = this.$store.getters["directory/getDirectory"];
			let coordinates = this.$store.getters["user/getCoordinates"];

			return directory.geolocation && coordinates;
		},
	},
	methods: {
		toggle(open) {
			this.open = open;
		},
		href(location) {
			window.open(location, "_blank");
		},
	},
};
</script>

<style scoped>
.card-content,
.location-contact {
	flex: 1 1 auto;
	flex-wrap: wrap;
}

.card-end,
.location-distance {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.card-content,
.location-address {
	overflow: hidden;
}

.card-end,
.location-distance {
	width: 80px;
	min-width: 80px;
	font-size: 15px;
}

.location-item:hover {
	background-color: #f9f9f9;
}
</style>