var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"content-class":"locations-dialog","max-width":"1320"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-row',{staticStyle:{"height":"100%"},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mapbox-container",attrs:{"cols":"12","md":"8"}},[_c('MglMap',{ref:"mapbox",staticClass:"mapbox",attrs:{"accessToken":_vm.accessToken,"mapStyle":_vm.mapStyle},on:{"update:mapStyle":function($event){_vm.mapStyle=$event},"update:map-style":function($event){_vm.mapStyle=$event},"load":_vm.onLoad}},[_vm._l((_vm.locationMarkers),function(location,index){return _c('div',{key:index},[(
							location.location.longitude &&
							location.location.latitude
						)?_c('MglMarker',{attrs:{"coordinates":[
							location.location.longitude,
							location.location.latitude ]},on:{"click":function($event){return _vm.openLocation(location)}}},[_c('div',{attrs:{"slot":"marker"},slot:"marker"},[_c('v-icon',{attrs:{"size":"40px","color":location.color}},[_vm._v("mdi-map-marker")])],1)]):_vm._e()],1)}),(
						_vm.currentCoordinates &&
						_vm.currentCoordinates[0] != 0 &&
						_vm.currentCoordinates[1] != 0
					)?_c('MglMarker',{attrs:{"coordinates":[
						_vm.currentCoordinates[1],
						_vm.currentCoordinates[0] ]}},[_c('div',{attrs:{"slot":"marker"},slot:"marker"},[_c('v-icon',{attrs:{"size":"40px","color":"#E60000"}},[_vm._v("mdi-map-marker-account")])],1)]):_vm._e()],2)],1),_c('v-col',{staticClass:"location-list",attrs:{"cols":"12","md":"4"}},_vm._l((_vm.locationCards),function(card){return _c('location-list-card',{key:card.id,ref:'card-' + card.id,refInFor:true,attrs:{"card":card},on:{"click":_vm.flyTo}})}),1)],1),_c('div',{staticClass:"location-close"},[_c('v-icon',{staticClass:"mt-1 mr-2",on:{"click":function($event){_vm.dialog = false}}},[_vm._v("mdi-close")])],1),_c('location-dialog',{ref:"locationDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }